(() => {
  const targets = document.querySelectorAll("[data-dynamic='year']");

  const setToCurrentYear = (target) => {
    const actualYear = new Date().getFullYear();

    target.textContent = actualYear;
  };

  targets.forEach(setToCurrentYear);
})();
