(() => {
  const mainSlider = () => {
    const labels = [
      "Inicio",
      "Cómo funciona",
      "Quiénes somos",
      "Servicios",
      "Experiencia",
      "Respaldo",
      "Contáctenos",
    ];
    const options = {
      direction: "vertical",
      mousewheelControl: true,
      slidesPerView: 1,
      speed: 600,
      mousewheel: true,
      allowTouchMove: false,
      navigation: {
        nextEl: ".main-swiper__next",
      },
      pagination: {
        el: ".main-swiper__pagination",
        clickable: true,
        renderBullet: function (index, className) {
          return `
            <span class="${className}">
              ${index + 1}
              <span class="swiper-pagination-label">${labels[index]}</span>
            </span>
          `;
        },
      },
    };
    const navbarBrand = document.querySelector(".l-header .navbar-brand");

    let swiper = new Swiper(".main-swiper", options);

    const handleResize = () => {
      if (innerHeight < 667 || innerWidth < 1200) swiper.destroy();
      else swiper = new Swiper(".main-swiper", options);
    };
    const goToFirst = (e) => {
      e.preventDefault();

      swiper.slideTo(0);
    };

    handleResize();
    addEventListener("resize", handleResize);
    navbarBrand.addEventListener("click", goToFirst);
  };

  const previewSlider = () => {
    const targets = [...document.querySelectorAll(".s-preview__card-out-slider .card")];
    const swiper = new Swiper(".s-preview__swiper", {
      slidesPerView: 1,
      draggable: true,
      loop: true,
      navigation: {
        nextEl: ".s-preview__next",
        prevEl: ".s-preview__prev",
      },
      breakpoints: {
        992: {
          slidesPerView: 2,
        },
      },
    });

    targets.forEach((target) => {
      target = target.outerHTML;

      const idString = 'id="card-bottom"';
      const idIndex = target.indexOf(idString);

      if (idIndex > -1) {
        let targetUpdated = target.split("");
        targetUpdated.splice(idIndex - 1, idString.length + 1);
        targetUpdated = targetUpdated.join("");

        target = targetUpdated;
      }

      swiper.prependSlide([`<div class="swiper-slide">${target}</div>`]);
    });
  };

  const servicesSlider = () => {
    new Swiper(".s-services__swiper", {
      slidesPerView: 1,
      loop: true,
      navigation: {
        nextEl: ".s-services__next",
        prevEl: ".s-services__prev",
      },
      pagination: {
        el: ".s-services__pagination",
        clickable: true,
        renderBullet: function (index, className) {
          index++;

          if (String(index).length < 2) index = "0" + index;

          return '<span class="' + className + '">' + index + "</span>";
        },
      },
    });
  };

  const experienceSlider = () => {
    new Swiper(".s-experience__swiper", {
      slidesPerView: 1,
      loop: true,
      draggable: true,
      navigation: {
        nextEl: ".s-experience__next",
        prevEl: ".s-experience__prev",
      },
      breakpoints: {
        1336: {
          loop: false,
          slidesPerView: 3,
          spaceBetween: 15,
        },
        992: {
          slidesPerView: 2,
        },
      },
    });
  };

  const companiesSlider = () => {
    new Swiper(".s-companies__swiper", {
      slidesPerView: 1,
      navigation: {
        nextEl: ".s-companies__next",
        prevEl: ".s-companies__prev",
      },
      spaceBetween: 50,
      scrollbar: {
        el: ".s-companies__scrollbar",
        dragSize: "auto",
        draggable: true,
      },
      breakpoints: {
        992: {
          slidesPerView: 4,
        },
        768: {
          slidesPerView: 2,
        },
      },
    });
  };

  const init = () => {
    mainSlider();
    previewSlider();
    experienceSlider();
    companiesSlider();
    servicesSlider();
  };

  init();
})();
