(() => {
  const menu = document.querySelector(".l-header");
  const sections = document.querySelectorAll("[data-bg-alt]");
  const lastSection = document.querySelector(".s-contact");
  const mainNext = document.querySelector(".main-swiper__next");
  const toggleMenu = document.querySelector(".navbar-toggler");

  const addDark = () => {
    menu.classList.add("l-header--dark");
  };

  const removeDark = () => {
    menu.classList.remove("l-header--dark");
  };

  const observer = new IntersectionObserver(
    ([entry]) => {
      if (entry.isIntersecting) return addDark();

      removeDark();
    },
    {
      root: null,
      threshold: 0.1,
    }
  );

  sections.forEach((section) => {
    observer.observe(section);
  });

  const lastSectionObserver = new IntersectionObserver(
    ([entry]) => {
      if (entry.isIntersecting) return mainNext.classList.add("main-swiper__next--end");

      mainNext.classList.remove("main-swiper__next--end");
    },
    {
      root: null,
      threshold: 0.1,
    }
  );

  lastSectionObserver.observe(lastSection);

  const toggleNavbarActivate = () => {
    menu.classList.toggle("l-header--actived");
  };

  toggleMenu.addEventListener("click", toggleNavbarActivate);
})();
