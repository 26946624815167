(() => {
  const playBtn = document.querySelector(".c-video__play");

  if (!playBtn) return;

  const icon = playBtn.querySelector("i");
  const { target } = playBtn.dataset;
  const video = document.querySelector(target);

  if (!video) throw "There aren't any video to be played";

  const playVideo = () => {
    video.play();
    icon.classList.replace("fa-play", "fa-pause");
  };

  const pauseVideo = () => {
    video.pause();
    icon.classList.replace("fa-pause", "fa-play");
  };

  const toggleVideo = () => {
    video.paused ? playVideo() : pauseVideo();
  };

  playBtn.addEventListener("click", toggleVideo);
})();
